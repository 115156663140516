import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';

import Layout from '~/components/Layout/Layout';

const NotFoundPage = ({ data }) => {
  const { pages, social, title, calendly } = data.site.siteMetadata;
  const meta = pages.home;

  return (
    <Layout meta={meta} socials={social} title={title} calendly={calendly}>
      <div className="page">
        <h1 className="page-title">404 - Not Found</h1>

        <div className="content">
          <p>We couldn't find the page you were looking for.</p>

          <p>
            Go back to the <Link to="/">homepage</Link>.
          </p>
        </div>
      </div>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  path: PropTypes.string,
  data: PropTypes.object
};

export default NotFoundPage;

// eslint-disable-next-line one-var
export const pageQuery = graphql`
  query NotFoundPage {
    site {
      siteMetadata {
        title
        calendly {
          domain
          kubeflow {
            url
            googleAnalytics {
              category
              action
              label
            }
          }
        }
        contacts {
          department
          email
        }
        pages {
          home {
            title
            description
            keywords
            googleSiteVerification
          }
        }
        social {
          twitter
          github
          linkedin
          email
          feed
        }
      }
    }
  }
`;
